import React from "react";

import * as styles from "./styles.module.scss";

import arrowIcon from "../../../images/link-arrow.svg";
import locationIcon from "../../../images/location.svg";
import { Link } from "gatsby";

export interface INewsPosting {
  id: number;
  title: string;
  author: string;
  body: string;
  category: string;
  location: string;
  coverimage: { formats: {}; url: string };
  createdAt: string;
}

const NewsPosting: React.FC<{ data: INewsPosting }> = (props) => {
  // extract the text of paragraph
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = props.data.body;

  // get first paragraph in body
  const paragraph = tempDiv.getElementsByTagName("p")[0];

  let content;
  if (paragraph) {
    content = paragraph.textContent || paragraph.innerText;
  }

  return (
    <Link to={`/news/${props.data.id}`} className={styles.post}>
      <img src={props.data.coverimage.url} className={styles.image} />
      <h3 className={styles.title}>{props.data.title}</h3>
      <p>{content}</p>
      <div className={styles.row}>
        {props.data.location && (
          <div className={styles.location}>
            <img src={locationIcon} className={styles.icon} alt='Location Icon' />
            <span>{props.data.location}</span>
          </div>
        )}
        <div className={styles.link}>
          <span>Beitrag lesen</span>
          <img src={arrowIcon} className={styles.arrow} alt='Arrow' />
        </div>
      </div>
    </Link>
  );
};

export default NewsPosting;
