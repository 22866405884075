import React from "react";

import brushImage from "../../../images/logo-brush.png";
import * as styles from "./styles.module.scss";

const Heading: React.FC<{
  firstHighlightedWord?: string;
  heading: string;
  lastHighlightedWord?: string;
  paragraph?: string;
  small?: boolean;
}> = (props) => {
  return (
    <div className={styles.container}>
      <h2 className={`${props.small && styles.small}`}>
        <div className={styles.highlightedWord}>
          <span> {props.firstHighlightedWord}</span>
          {props.firstHighlightedWord && (
            <img src={brushImage} alt='Orange Brush' className={styles.brush} />
          )}
        </div>
        {props.heading}
        <div className={styles.highlightedWord}>
          <span> {props.lastHighlightedWord}</span>
          {props.lastHighlightedWord && (
            <img src={brushImage} alt='Orange Brush' className={styles.brush} />
          )}
        </div>
      </h2>
      {props.paragraph && <p>{props.paragraph}</p>}
    </div>
  );
};

export default Heading;
