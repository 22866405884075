import React, { useEffect, useState } from "react";

import * as styles from "./styles.module.scss";
import NewsPosting, { INewsPosting } from "../NewsPosting";
import { apiUrl } from "../../../app.config";
import OfferPosting from "../OfferPosting";
import { Link } from "gatsby";

export interface INewsOverview {
  newsfiles: string[];
}

export interface INewsFile {
  newspostings: INewsPosting[];
}

const Overview: React.FC<{ category: "Neuigkeit" | "Angebot"; teaser: boolean }> = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [overview, setOverview] = useState<INewsOverview>();
  const [index, setIndex] = useState<number>(-1);
  const [maxIndex, setMaxIndex] = useState<number>(-1);

  const [posts, setPosts] = useState<INewsPosting[]>([]);

  let initialNumberOfPosts = 6;
  if (props.teaser) {
    initialNumberOfPosts = 3;
  }
  const [visiblePostsIndex, setVisiblePostsIndex] = useState(initialNumberOfPosts);

  useEffect(() => {
    setLoading(true);
    fetch(`${apiUrl}/news/news-overview.json`)
      .then((r) => r.json())
      .then((overview: INewsOverview) => {
        if (overview.newsfiles.length === 0) {
          setLoading(false);
          setError(true);
          return;
        }
        setOverview(overview);
        setIndex(0);
        setMaxIndex(overview.newsfiles.length - 1);
      });
  }, []);

  useEffect(() => {
    if (!overview?.newsfiles) return;

    setLoading(true);
    fetch(`${apiUrl}/news/${overview?.newsfiles[index]}`)
      .then((r) => r.json())
      .then((newsfile: INewsFile) => {
        setPosts((cur) => cur.concat(newsfile.newspostings));
        if (index < maxIndex) setIndex((cur) => (cur += 1));
        setLoading(false);
      });
  }, [index]);

  let filteredPosts = posts.filter((post) => post.category === props.category);

  let allPostsLoaded = visiblePostsIndex >= filteredPosts.length;

  const loadMore = () => {
    if (allPostsLoaded) {
      return;
    }

    const leftPosts = filteredPosts.length - visiblePostsIndex;

    const lessThanThreePostsLeft = leftPosts < 3;
    if (lessThanThreePostsLeft) {
      setVisiblePostsIndex((prevPosts) => prevPosts + leftPosts);
      return;
    }

    setVisiblePostsIndex((prevPosts) => prevPosts + 3);
  };

  const visiblePosts = filteredPosts.slice(0, visiblePostsIndex);

  if (props.teaser) {
    return (
      <>
        <div className={styles.postings}>
          {props.category === "Neuigkeit" &&
            visiblePosts &&
            visiblePosts.map((post) => <NewsPosting key={post.id} data={post} />)}
          {props.category === "Angebot" &&
            visiblePosts &&
            visiblePosts.map((post) => (
              <OfferPosting
                key={post.id}
                data={{ id: post.id, title: post.title, coverimage: post.coverimage }}
              />
            ))}
        </div>
        <Link to='/news'>
          <button className={styles.loadButton}>Mehr anzeigen</button>
        </Link>

        <div className={styles.bottomContainer}>
          {loading && !error && <div className={styles.loader}></div>}
          {error && <Error />}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.postings}>
        {props.category === "Neuigkeit" &&
          visiblePosts &&
          visiblePosts.map((post) => <NewsPosting key={post.id} data={post} />)}
        {props.category === "Angebot" &&
          visiblePosts &&
          visiblePosts.map((post) => (
            <OfferPosting
              key={post.id}
              data={{ id: post.id, title: post.title, coverimage: post.coverimage }}
            />
          ))}
      </div>
      {allPostsLoaded === false && (
        <button className={styles.loadButton} onClick={() => loadMore()}>
          Mehr laden
        </button>
      )}

      <div className={styles.bottomContainer}>
        {loading && !error && <div className={styles.loader}></div>}
        {error && <Error />}
      </div>
    </>
  );
};

export default Overview;

const Error = () => {
  return (
    <>
      <svg
        width='319'
        height='319'
        viewBox='0 0 319 319'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='159.5' cy='159.5' r='145' fill='#FFDFA2' stroke='#F5C23C' stroke-width='29' />
        <path
          d='M159.5 222.568C155.506 222.568 152.063 221.122 149.173 218.232C146.282 215.341 144.837 211.899 144.837 207.904C144.837 203.805 146.282 200.336 149.173 197.498C152.063 194.66 155.558 193.241 159.658 193.241C163.652 193.241 167.068 194.686 169.906 197.577C172.744 200.468 174.163 203.91 174.163 207.904C174.163 212.004 172.744 215.472 169.906 218.31C167.068 221.149 163.6 222.568 159.5 222.568ZM146.729 177.474V97.2207H172.271V177.474H146.729Z'
          fill='#F5C23C'
        />
      </svg>
      <h3>Oh, da ist etwas schiefgelaufen!</h3>
      <p>Bitte versuche es später erneut.</p>
    </>
  );
};
