import * as React from "react";
import Layout from "../components/layout/Layout";
import Heading from "../components/ui/Heading";
import ResponsiveContainer from "../components/ui/ResponsiveContainer";
import Overview from "../components/media/Overview";

const OffersOverviewPage = () => {
  return (
    <Layout title='Angebote'>
      <ResponsiveContainer>
        <Heading firstHighlightedWord='Angebote' heading=' mit Wertschätzung' small />
        <Overview category='Angebot' />
      </ResponsiveContainer>
    </Layout>
  );
};

export default OffersOverviewPage;
