import { Link } from "gatsby";
import React from "react";

import * as styles from "./styles.module.scss";

interface IOfferPosting {
  id: number;
  title: string;
  coverimage: { formats: {}; url: string };
}

const OfferPosting: React.FC<{ data: IOfferPosting }> = (props) => {
  return (
    <Link to={`/offers/${props.data.id}`} className={styles.offer}>
      <img src={props.data.coverimage.url} className={styles.image} />
      <h3 className={styles.title}>{props.data.title}</h3>
      <div className={styles.background}></div>
    </Link>
  );
};

export default OfferPosting;
